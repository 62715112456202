@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  height: 100%;
  font-size: 19px;
}

body {
  font-family: 'Montserrat', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
  font-size: 19px;
}

#root {
  height: 100%;
}

.tile:hover {
  outline: 1px solid gray;
  outline-offset: -1px;
}

.tile.selected {
  outline: 2px solid red;
  outline-offset: -2px;
}

html { font-size: 14px; line-height: 20px;  }

@media (min-width: 1280px) {
  html { font-size: 16px; line-height: 24px;  }
}

@media (min-width: 1536px) {
  html { font-size: 20px; line-height: 28px;  }
}

@media (min-width: 1920px) {
  html { font-size: 24px; line-height: 32px;  }
}

@media (min-width: 2400px) {
 html { font-size: 28px; line-height: 36px;  }
}

@media (min-width: 3000px) {
 html { font-size: 36px; line-height: 48px;  }
}

@media (min-width: 4000px) {
 html { font-size: 64px; line-height: 56px;  }
}
